<template>
  <div>
    <div v-if="isshowgrid">
      <va-card title="allowed app">
        <div class="row">
          <div class="flex md6 mt-2">
            <va-input class="va-input-search-container"
              :value="term"
              placeholder="search"
              @input="search"
              removable
            >
              <va-icon name="fa fa-search" slot="prepend" />
            </va-input>
          </div>
          <div class="flex md6 text-right">
            <va-button
              v-if="admin_role || supportRole"
              icon="fa fa-plus"
              class="my-0"
              @click.prevent="add()">
              Create
            </va-button>
          </div>
        </div>
        <va-data-table
          :fields="fields"
          :data="filteredData"
          :per-page="parseInt(perPage)"
          style="max-height: 90vh;">
          <template slot="allowed" slot-scope="props">
            <va-badge
              v-if="(props.rowData.allowed_default == 1) || (props.rowData.allowed_default == true)"
              class="e-badge e-badge-primary size_1"
              color="#32a846"
              :disabled="true"
            >
              Enable
            </va-badge>
            <va-badge
              v-if="(props.rowData.allowed_default == 0) || (props.rowData.allowed_default == false)"
              class="e-badge e-badge-primary size_1"
              color="#F7A000"
              :disabled="true">
              Disable
            </va-badge>
          </template>
          <template v-if="admin_role || supportRole" slot="actions" slot-scope="props">
            <va-button
              flat
              small
              color="gray"
              icon="fa fa-pencil"
              @click="edit(props.rowData)"
              class="ma-0">
            </va-button>
          </template>
          <template v-if="admin_role || supportRole" slot="action" slot-scope="props">
            <va-button
              flat
              small
              color="red"
              icon="fa fa-remove"
              @click="remove(props.rowData)"
              class="ma-0">
            </va-button>
          </template>

        </va-data-table>
        <va-modal
          v-model="showRemoveModal"
          title="Delete Allowed App"
          size='small'
          :message="msg"
          okText="Confirm"
          cancelText="Cancel"
          @ok="deleteAllowedApp(entity)"
          @cancel="cancel()">
        </va-modal>
      </va-card>
    </div>
    <div v-if="isshowform">
      <va-card :title="title">
        <template slot="actions">
          <va-button color="gray" @click="list()">List</va-button>
        </template>
        <div>
          <form>
            <span class="va-form-label va-form-required-label">App Name</span>
            <va-input
              v-model="app_name"
              type="text"
              placeholder="Enter Your App Name"
              :error="!!AppNameErrors.length"
              :error-messages="AppNameErrors"
            />
            <span class="va-form-label va-form-required-label">Package Name</span>
            <va-input
              v-model="package_name"
              type="text"
              placeholder="Enter Your Packge Name"
              :error="!!PackageNameErrors.length"
              :error-messages="PackageNameErrors"
            />
            <span class="va-form-label va-form-required-label">App Description</span>
            <va-input
              v-model="app_description"
              type="text"
              placeholder="Enter Your Description"
              :error="!!AppDescriptionErrors.length"
              :error-messages="AppDescriptionErrors"
            />
            <span class="va-form-label va-form-required-label">Version</span>
            <va-input
              v-model="version"
              type="number"
              :disabled = "true"
              :error="!!VersionErrors.length"
              :error-messages="VersionErrors"
            />
            <span class="va-form-label va-form-required-label">Priority</span>
            <va-input
              v-model="priority"
              type="number"
              placeholder="Enter Priority"
              :error="!!priorityErrors.length"
              :error-messages="priorityErrors"
            />
            <span v-if="isCreate" class="va-form-label va-form-required-label">Banner Image (1280px X 720px)</span>
            <span v-if="isUpdate" class="va-form-label">Banner Image (1280px X 720px)</span>
            <va-file-upload
              type="single"
              dropzone
              @input="CheckFile('banner')"
              v-model="banner_file"
            />
            <div class="mt-3">
              <span v-if="isCreate" class="va-form-label va-form-required-label mt-2">Background Image (1280px X 720px)</span>
              <span v-if="isUpdate" class="va-form-label mt-2">Background Image (1280px X 720px)</span>
              <va-file-upload
                type="single"
                dropzone
                @input="CheckFile('background')"
                v-model="background_file"
              />
            </div>
            <div class="mt-3">
              <span v-if="isCreate" class="va-form-label va-form-required-label">APK</span>
              <span v-if="isUpdate" class="va-form-label ">APK</span>
              <va-file-upload
                type="single"
                dropzone
                v-model="apk_file"
              />
              <!--    <div class="flex md12 sm12 xs12">
                <input
                  type="file"
                  ref="apk_file"
                  style="display: none;"
                  v-on:change="validateApk($event,'apk')"
                  id="apk_file"
                  accept=".apk"><br>
                <va-button type="submit" class="my-0" @click="$refs.apk_file.click()">Upload APK</va-button>
                <label>{{ apk_file_name }}</label>
              </div>  -->
            </div>
            <div class="mt-3">
              <va-checkbox
                v-model="allowed_default"
                label="Default"
              />
            </div>
            <div>
              <va-button
                class="ml-1 mt-2"
                @click.prevent="upOper()">
                Update Operators
              </va-button>
            </div>
            <va-modal
              class="flex xs12 md12 len"
              v-model="isShowOper"
              size="large"
              noEscDismiss
              noOutsideDismiss
              okText="Update"
              cancelText="Cancel"
              @ok="finish()"
              @cancel="cancel()"
            >
              <span style="font-size: 21px;"><b>Select Operators</b></span>
              <slot style="margin: 50px;">
                <div class="va-table">
                  <table style="width: 100%;" class="va-table-responsive">
                    <thead>
                      <tr>
                        <th class="content-th" width="50%">Organization</th>
                        <th class="content-th" width="50%">Vendor Code</th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(field,i) in operFields" :key="field.id">
                        <td v-if="(isCreate || isUpdate) && !field.oper_update_flag">
                          <multiselect
                            placeholder="Select Organization"
                            v-model="field.organization_name"
                            :searchable="true"
                            :close-on-select="true"
                            :clear-on-select="false"
                            :allow-empty="false"
                            track-by="id"
                            id="example"
                            :multiple="false"
                            label="org_name"
                            :options="organization_nameArr"
                          />
                        </td>
                        <td v-if="isUpdate && field.oper_update_flag">
                          <multiselect
                            placeholder="Select Organization"
                            v-model="field.organization_name"
                            :close-on-select="true"
                            :clear-on-select="false"
                            :allow-empty="false"
                            track-by="id"
                            id="example"
                            :multiple="false"
                            label="org_name"
                            :options="organization_nameArr"
                            :disabled="true"
                          />
                        </td>
                        <td v-if="(isCreate || isUpdate) && !field.oper_update_flag">
                          <multiselect
                            placeholder="Select vendor"
                            v-model="field.vendor_code"
                            :close-on-select="false"
                            :clear-on-select="false"
                            :preserve-search="true"
                            :hide-selected="false"
                            :taggable="false"
                            track-by="id"
                            id="example"
                            :multiple="true"
                            label="vendor_code"
                            :options="vendorArr"
                          />
                        </td>
                        <td v-if="isUpdate && field.oper_update_flag">
                          <multiselect
                            placeholder="Select vendor"
                            v-model="field.vendor_code"
                            :close-on-select="false"
                            :clear-on-select="false"
                            :preserve-search="true"
                            :hide-selected="false"
                            :taggable="false"
                            track-by="id"
                            id="example"
                            :multiple="true"
                            label="vendor_code"
                            :options="vendorArr"
                            :disabled="true"
                          />
                        </td>
                        <td>
                          <va-button
                            v-if="operFields.length != 0"
                            class="content_width toCenter"
                            flat color="danger"
                            @click.prevent="removeTechField(i)"
                            icon="entypo entypo-cancel" />
                        </td>
                        <td>
                          <va-button
                            v-if="operFields.length - 1 == i"
                            class="content_width toCenter"
                            flat color="primary"
                            @click.prevent="fields_length++"
                            icon="entypo entypo-plus" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </slot>
            </va-modal>
            <div class="d-flex justify--end mt-4">
              <va-button
                type="submit"
                class="my-0 va-button-normal-cancel"
                @click="list()">
                Cancel
              </va-button>
              <va-button
                v-if="isCreate"
                type="submit"
                class="my-0"
                @click.prevent="createAllowedApp()">
                Create
              </va-button>
              <va-button
                v-if="isUpdate"
                type="submit"
                class="my-0"
                @click.prevent="updateAllowedApp()">
                Update
              </va-button>
            </div>
          </form>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>

import Vue from 'vue'
import vueResource from 'vue-resource'
import { search } from '../../i18n/search.js'
import config from '../../i18n/en.json'
import REGEX from '../../i18n/regex_pattern'
Vue.use(vueResource)

export default {
  name: 'allowed_app',
  beforeCreate () {
    const loader = Vue.$loading.show({ width: 40, height: 40 })
    	this.$http.get(config.menu.host + '/org')
      .then(resp => {
        loader.hide()
        this.organization_nameArr = []
        this.organization_nameArr = resp.body.filter(function (value, count) {
          value.id = value.org_id
          if (value.org_type == 'OPERATOR' || value.org_type == 'HEPI_OPERATOR') {
            return !resp.body.some(function (data) {
              return value.org_name === data.name
            })
          }
        })
        this.$http.get(config.menu.host + '/vendor').then(response => {
          this.vendorArr = response.body.filter(function (thing, index) {
            thing.id = index + 1
            return thing
          })
        })
        // this.$http.get(config.menu.host + '/allowed_app')
        //   .then(response => {
        // 	loader.hide()
        // 	this.allowedApp_list = response.body
        // 	this.isshowgrid = true
        //     this.getAllAllowedApps()
        //   })
         this.getAllAllowedApps()
      }, error => {
        	loader.hide()
      	},
      )
  },
  data () {
    return {
      term: null,
    	perPage: '10',
    	perPageOptions: ['4', '6', '10', '20'],
    	app_name: '',
    	AppNameErrors: [],
    	package_name: '',
    	PackageNameErrors: [],
    	app_description: '',
    	version: 1,
      priority: '',
    	AppDescriptionErrors: [],
    	VersionErrors: [],
      priorityErrors:[],
    	banner_file: [],
    	background_file: [],
    	apk_file: [],
      apk_file_name: '',
    	allowed_default: [],
    	organization_name: '',
    	organization_nameArr: [],
      vendorArr: [],
      vendor_code: '',
      isShowOper: false,
    	isshowgrid: true,
    	isshowform: false,
    	isCreate: false,
    	isUpdate: false,
      admin_role: false,
    	showRemoveModal: '',
    	msg: '',
    	allowedApp_list: [],
      operFields: [{ organization_name: '', vendor_code: [] }],
      fields_length: 0,
      oper_update_flag: false,
     supportRole:false
    }
  },
  watch: {
    fields_length () {
      this.operFields.push({ organization_name: '', vendor_code: [], vendorArr: [] })
    },
  },
  computed: {
    formReady () {
      return !this.AppNameErrors.length &&
							 !this.PackageNameErrors.length &&
							 !this.AppDescriptionErrors.length &&
							 !this.VersionErrors.length &&
               !this.priorityErrors.length
    },
    Checkfile_upload () {
	      if (this.banner_file[0] && this.background_file[0]) {
	        return true
	      } else {
	        Vue.notify({ text: 'Please Upload Files', type: 'error' })
	        return false
	      }
	    },
    fields () {
	      return [{
	        name: 'app_name',
	        title: 'App Name',
	      },
	      {
	        name: 'package_name',
	        title: 'Package Name',
	      },
	      // {
	      //   name: '__slot:allowed',
	      //   title: 'Default',
	      // },
        {
          name: 'priority',
          title: 'Priority',
        },
	      {
	        name: '__slot:actions',
	        dataClass: 'text-right',
	      },
	      {
	        name: '__slot:action',
	        dataClass: 'text-right',
	      }]
	    },
	    filteredData () {
	      return search(this.term, this.allowedApp_list)
	    },
  },
  methods: {
    removeTechField (index) {
      if(this.operFields.length == 1 && index == 0){
        this.operFields.push({ organization_name: '', vendor_code: [], vendorArr: [] })
      }
      this.operFields = this.operFields.filter((_field, i) => i != index)
    },
    getAllAllowedApps () {
      const loader = Vue.$loading.show({ width: 40, height: 40 })
		    this.$http.get(config.menu.host + '/allowed_app')
	      .then(response => {
	        loader.hide()
	        this.allowedApp_list = response.body
	        this.isshowgrid = true
		      this.isshowform = false
          var role = this.$cookies.get('userRole')
          this.admin_role = false
          if (role == 'ADMIN') {
            this.admin_role = true
          }
	  if(role == 'SUPPORT') {
            this.supportRole =true
          }
	      }, error => {
	        loader.hide()
	        if (error && error.body) {
	          Vue.notify({ text: error.body, type: 'error' })
	        }
	      })
    },
    finish () {
      var vm = this
      function isEmpty(obj) {
        for(var key in obj) {
          if(obj.hasOwnProperty(key))
          return false;
        }
        return true;
      }
      this.operFields.map(function (map1) {
        if ((isEmpty(map1.organization_name) == false) && map1.vendor_code == '') {
          Vue.notify({ text: 'Please select vendor for organization', type: 'error' })
          return vm.isShowOper = true
        }
      })
    },
    upOper () {
      this.isShowOper = true
      if (this.isCreate) {
        this.operFields = [{ organization_name: '', vendor_code: '' }]
      }
    },
    cancel () {
      this.isShowOper = false
    },
    createAllowedApp () {
      this.AppNameErrors = this.app_name ? [] : ['App Name is required']
      this.PackageNameErrors = this.package_name ? [] : ['Package Name is required']
      this.AppDescriptionErrors = this.app_description ? [] : ['Description is required']
      this.VersionErrors = this.version ? [] : ['Version is required']
      this.priorityErrors = this.priority ? [] : ['Priority is required']
      if (!this.formReady) {
        	return
      	}
      	if (!this.Checkfile_upload) {
        	return
      	}
      var allowedoper = []
      var vendor_arr = []
      var flag = true
      if(this.operFields[0].organization_name != ''){
        this.operFields.map(function (oper) {
          var obj = {
            org_id: oper.organization_name.org_id,
            reseller_org_id: oper.organization_name.reseller_org_id,
            org_name: oper.organization_name.org_name,
            org_type: oper.organization_name.org_type,
          }
          allowedoper.push(obj)
          if(oper.vendor_code && oper.vendor_code.length > 0) {
            oper.vendor_code.map(function (data, i) {
              var object = {
                vendor_code: data.vendor_code,
                vendor_name: data.vendor_name,
                org_id: oper.organization_name.org_id,
              }
              vendor_arr.push(object)
            })
          }else{
            flag = false
          }
        })
      }
      if(flag){
      	var payload = new FormData()
      	payload.append('app_name', this.app_name)
      	payload.append('package_name', this.package_name)
      	payload.append('app_description', this.app_description)
      	payload.append('version', this.version)
        payload.append('priority',this.priority)
      	payload.append('banner_name', this.banner_name)
      	payload.append('background_name', this.background_name)
      	payload.append('banner_image_content', this.banner_file[0])
      	payload.append('background_image_content', this.background_file[0])
      	payload.append('apk_content', this.apk_file[0])
      	payload.append('allowedappsoperators', JSON.stringify(allowedoper))
        payload.append('allowedappsvendors', JSON.stringify(vendor_arr))
      	payload.append('allowed_default', this.allowed_default)

      	const loader = Vue.$loading.show({ width: 40, height: 40 })
      	this.$http.post(config.menu.host + '/allowed_app', payload)
        .then(responseData => {
          loader.hide()
          Vue.notify({ text: responseData.body, type: 'success' })
	  			this.getAllAllowedApps()
          this.list()
        }, err => {
          loader.hide()
          if (err && err.body) {
            Vue.notify({ text: err.body, type: 'error' })
          }
        })
      }
    },
    updateAllowedApp () {
      this.AppNameErrors = this.app_name ? [] : ['App Name is required']
      this.PackageNameErrors = this.package_name ? [] : ['Package Name is required']
      this.AppDescriptionErrors = this.app_description ? [] : ['Description is required']
      this.VersionErrors = this.version ? [] : ['Version is required']
      this.priorityErrors = this.priority ? [] : ['priority is required']

      if (!this.formReady) {
        	return
      	}
      var allowedoper = []; var vendor_arr = []
      var flag = true
      if(this.operFields[0] && this.operFields[0].organization_name != ''){
        this.operFields.map(function (oper) {
          var obj = {
            org_id: oper.organization_name.org_id ? oper.organization_name.org_id : oper.org_id,
            reseller_org_id: oper.organization_name.reseller_org_id ? oper.organization_name.reseller_org_id : oper.reseller_org_id,
            org_name: oper.organization_name.org_name ? oper.organization_name.org_name : oper.org_name,
            org_type: oper.organization_name.org_type ? oper.organization_name.org_type : oper.org_type,
          }

          allowedoper.push(obj)
          if(oper.vendor_code && oper.vendor_code.length > 0){
            oper.vendor_code.map(function (data, i) {
              var object = {
                vendor_code: data.vendor_code,
                vendor_name: data.vendor_name,
                org_id: oper.organization_name.org_id ? oper.organization_name.org_id : oper.org_id,
              }
              vendor_arr.push(object)
            })
          }else if(!oper.vendor_code){
            flag = false
          }
        })
      }
      if(flag){
      	var payload = new FormData()
      	payload.append('app_name', this.app_name)
      	payload.append('package_name', this.package_name)
      	payload.append('app_description', this.app_description)
        if (this.apk_file[0] == undefined) {
        		payload.append('version', this.version)
        } else {
          	payload.append('version', Number(this.version) + 1)
        }
      	payload.append('banner_name', this.banner_name)
        payload.append('app_id', this.app_id)
      	payload.append('background_name', this.background_name)
      	payload.append('banner_image_content', this.banner_file[0])
      	payload.append('background_image_content', this.background_file[0])
      	payload.append('apk_content', this.apk_file[0])
      	payload.append('allowedappsoperators', JSON.stringify(allowedoper))
        payload.append('allowedappsvendors', JSON.stringify(vendor_arr))
	      payload.append('allowed_default', this.allowed_default)
        payload.append('priority',this.priority)
      	const loader = Vue.$loading.show({ width: 40, height: 40 })
      	this.$http.put(config.menu.host + '/allowed_app', payload)
        .then(responseData => {
          loader.hide()
          Vue.notify({ text: responseData.body, type: 'success' })
          this.list()
          // this.getAllAllowedApps()
        }, err => {
          loader.hide()
          if (err && err.body) {
            Vue.notify({ text: err.body, type: 'error' })
          }
        })
      }else{
        Vue.notify({ text: "Please select vendor for organization", type: 'error' })
      }
    },
    validateApk (eve, type) {
      var apk = eve.target.files[0]
      if (type == 'apk') {
        this.apk_file_name = apk.name
        this.apk_file = apk
      }
    },
    CheckFile (type) {
      if (typeof (type) !== 'undefined') {
        var image_file;
        (type == 'banner') ? image_file = this.banner_file[0] : image_file = this.background_file[0]
        if (((type == 'banner') || (type == 'background')) && (image_file !== undefined)) {
		        var file_name = image_file.name.split('.').pop()
		        if ((file_name.toLowerCase() === 'png' || 'jpg' || 'jpeg')) {
		          const img = new Image()
		          let width = '1280'
		          let height = '720'
		          if (type === 'banner') {
		            width = '1280'
		            height = '720'
		          }
		          const reader = new FileReader()
		          reader.readAsDataURL(image_file)
		          reader.onload = evt => {
		            img.onload = () => {
		              if (img.width == width && img.height == height) {
		                (type == 'banner') ? this.banner_name = image_file.name : this.background_name = image_file.name
		              } else {
		                (type === 'banner') ? this.banner_file = '' : this.background_file = ''
		                var flag = this.logo_file ? 'banner' : 'background'
		                if (flag === 'banner') {
		                //   this.banner_file = []
		                // } else {
		                  this.background_file = []
		                }
		                Vue.notify({ text: 'Please Check The Image Size', type: 'error' })
		              }
		            }
		            img.src = evt.target.result
		          }
		        } else {
		          (type === 'banner') ? this.banner_file = '' : this.background_file = ''
		          Vue.notify({ text: 'Please Check The Image Format', type: 'error' })
		        }
		      }
		    }
		  },
    add () {
      this.title = 'Create Allowed App'
      this.isshowgrid = false
      this.isshowform = true
      this.isCreate = true
      this.isUpdate = false
      this.app_name = ''
      this.AppNameErrors = []
      this.package_name = ''
      this.PackageNameErrors = []
      this.app_description = ''
      this.version = 1
      this.priority = ''
      this.AppDescriptionErrors = []
      this.VersionErrors = [] = []
      this.priorityErrors = []
      this.banner_file = []
      this.background_file = []
      this.apk_file = []
      this.allowed_default = false
      this.organization_name = ''
      this.banner_file = []
      this.background_file = []
      this.apk_file = []
      this.operFields = [{ organization_name: '', vendor_code: '' }]
    },
    edit (row) {
      this.title = 'Update Allowed App'
      this.isshowgrid = false
      this.isshowform = true
      this.isCreate = false
      this.isUpdate = true
      this.app_id = row.app_id
      this.app_name = row.app_name
      this.package_name = row.package_name
      this.app_description = row.app_description
      this.version = row.version
      this.priority = row.priority
      this.AppNameErrors = []
      this.PackageNameErrors = []
      this.AppDescriptionErrors = []
      this.VersionErrors = []
      this.priorityErrors = []
      this.allowed_default = row.allowed_default
      this.banner_file = []
      this.background_file = []
      this.apk_file = []
      var final_data = []; var vm = this
      if(row.allowedappsoperators.length == 0 && row.allowedappsvendors.length == 0){
        this.operFields = [{ organization_name: '', vendor_code: '' }]
      }else{
        row.allowedappsoperators.map(function (data, x) {
          var org_data = {}
          org_data.org_name = data.org_name
          org_data.id = data.org_id
          data.organization_name = [org_data]
          data.oper_update_flag = !!(data.organization_name)
          var arr = []
          if(row.allowedappsoperators.length > 0 && row.allowedappsvendors == 0){
            final_data.push(data)
          }
          row.allowedappsvendors.map(function (iter, i) {
            iter.id = i
            if (data.org_id == iter.org_id) {
              arr.push(iter)
            }
            if (i + 1 == row.allowedappsvendors.length) {
              data.vendor_code = arr
              final_data.push(data)
            }
          })
        })
        this.operFields = final_data
      }
    },
    remove (row) {
	      this.msg = 'Are you sure to delete the allowed App ' + row.package_name + ' ?'
	      this.entity = row
	      this.showRemoveModal = true
	    },
	    cancel () {
	      this.showRemoveModal = false
	    },
	    deleteAllowedApp (data) {
	      this.$http.delete(config.menu.host + '/allowed_app/' + data.app_id)
	      .then(resp => {
	        Vue.notify({ text: resp.body, type: 'success' })
          this.getAllAllowedApps()
	        this.list()
	      }, err => {
	        if (err && err.body) {
	          Vue.notify({ text: err.body, type: 'error' })
	        }
	      })
	    },
	    list () {
	    	this.getAllAllowedApps()
	      this.isshowgrid = true
	      this.isshowform = false
	    },
	    search: function (term) {
	      this.term = term
	    },
  },
}
</script>

<style lang="scss">

</style>
